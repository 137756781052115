import {aicoreBackendPetition} from "@/utils/aicore_petition";
import {showNotification} from "@/utils/aux_functions";
import store from "@/store";
import axios from "axios";
import {aicoreLookerBackendPetition} from "@/utils/aicore-looker_petition";

export async function getAdhocDashboardsWS() {
    try {
        return await aicoreBackendPetition('GET', "/LookerAdhoc/adhoc_dashboards", {}, {ext: true})
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + ' /LookerAdhoc/adhoc_dashboards')

    }
}

export async function getAdhocDashboardLooksWS(dashboard_id) {
    try {
        return await aicoreBackendPetition('GET', "/LookerAdhoc/adhoc_dashboard_looks/{dashboard_id}?adhoc_dashboard_id="+dashboard_id, {}, {ext: true})
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + ' /LookerAdhoc/adhoc_dashboard_looks')

    }
}
export async function runAdhocLookWS(look_id, filters = {}) {
    try {
        return await aicoreBackendPetition('POST', "/LookerAdhoc/run_adhoc_look/"+look_id, JSON.stringify(filters), { content_type: 'application/json', ext: true})
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + ' /LookerAdhoc/run_adhoc_look')
        console.log(e)
    }
}

export function getDateFilterNames(id_looker) {
    switch (id_looker) {
        case 1:
        case 3:
        case 14:
        case 15:
        case 16:
        case 17:
        case 19:
            return 'matriculas.date_filter'
        case 5:
        case 8:
            return null
        case 6:
        case 7:
        case 13:
        case 18:
        case 22:
            return 'matriculas.fecha_date'
        case 31:
        case 32:
        case 9:
            return 'objetivos_ventas.date'
        case 25:
        case 26:
        case 27:
            return 'llamadas.fecha_llamada_date'
    }
}

/*Obtiene un array con todos las claves que son nodos hoja*/
export const getObjectKeyNames = (object) => {
    let row_names = []
    try {
        for (const [key, value] of Object.entries(object)){
            if (typeof value !== 'object' || (value == null)){
                if(!row_names.some(element => element === key)){
                    row_names.push(key)
                }
            }else{
                row_names.push(...getObjectKeyNames(value).filter(data => !row_names.some(row => row === data)))
            }
        }
        return row_names
    }catch (e){
        console.log(e)
    }
}

/*Obtiene un array con todos los valores de cada objeto*/
export const getObjectValue = (object) => {
    let row_values = []

    for (const [key, value] of Object.entries(object)){
        if (typeof value !== 'object' || (value == null)){
            row_values.push(value)
        }else{
            row_values.push(...getObjectValue(value))
        }
    }
    return row_values
}


//OBTIENE LA COMPARATIVA DE DATOS CON RESPECTO AL AÑO ANTERIOR
export async function getComparativeLastYearWS(nickname_tecnico_innotutor,fecha_inicial,fecha_final) {
    try {
        const filters = {nickname_tecnico_innotutor,fecha_inicial, fecha_final}
        return await aicoreBackendPetition('GET', "/gamificacion/get_comparative_last_year", filters, {
            content_type: 'application/json',
            ext: true
        })
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + ' /LookerAdhoc/run_adhoc_look')
        console.log(e)
    }
}


//Obtiene el listado de Dashboards
export async function getAllLookerDashboardsWS(){
    let params = {}

    try {
        return await aicoreLookerBackendPetition('GET', '/dashboard/', params)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + '/dashboard/');
    }
}

//Obtiene el listado de Dashboards
export async function getAllLookerFoldersWS(){
    let params = {}

    try {
        return await aicoreLookerBackendPetition('GET', '/folders/', params)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + '/folders/');
    }
}

export async function getAccessRequestWS(only_unhandled = true){
    let params = {
        only_unhandled: only_unhandled
    }

    try {
        return await aicoreLookerBackendPetition('GET', '/access_requests/', params, {content_type: 'application/json'})

    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + '/access_requests/');
    }
}

export async function createAccessRequestWS(id_dashboard){
    let params = {
        id_dashboard: id_dashboard,
        id_user: store.getters.getUsername,
    }

    let formData = new FormData();

    formData.append("id_dashboard",id_dashboard)
    formData.append("id_user", store.getters.getUsername)

    try {
        return await aicoreLookerBackendPetition('POST', '/access_requests/', JSON.stringify(params), {petition: 'axios', content_type: 'application/json'})

    } catch (e) {
        console.log(e)
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + '/access_requests/');
        return e
    }
}

export async function handleAccessRequestWS({request_id, action}){
    let params = {}

    let handled_by = store.getters.getUsername

    try {
        return await aicoreLookerBackendPetition('POST', `/procedures/access_request/handle/${request_id}?action=${action}&handled_by=${handled_by}` , JSON.stringify(params), {content_type: 'application/json'})

    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + '/procedures/access_request/handle/');
    }
}

export async function getDashboardAccessByUserWS(username) {
    let url = `/users/${username}/dashboards`;

    try {
        return await aicoreLookerBackendPetition('GET', url, null, {content_type: 'application/json', petition: 'axios'})
    } catch (e) {
        if (axios.isAxiosError(e)){
            return e.response
        }else{
            showNotification('error', process.env.VUE_APP_ERROR_SERVER + ` /users/${username}/dashboards`)
        }
    }

}

export async function deleteDashboardAccessByUserWS(username, id_dashboard) {
    let url = `/users/${username}/dashboards/${id_dashboard}/deactivate`;

    try {
        return await aicoreLookerBackendPetition('PUT', url, {}, {content_type: 'application/json'})
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + ` /users/${username}/dashboards/${id_dashboard}/deactivate`)
    }

}
