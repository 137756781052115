import {
    belongGroup, getAdminGroups, getAssignFFVVGroups,
    getAudiovisualGroups, getDashboardAccessGroups,
    getObjectivesEditorsGroups, getRRHHGroups, getScriptCourseGeneratorGroups, getTranslatorGroups,
} from "@/utils/auth";

export function systemRoute() {

    return [
        {
            name: 'AICORE - Sistemas de gestión',
            children: [
                {
                    name: 'Creación de video producto',
                    path: '/creacion-video-producto',
                    active: true,
                    access: belongGroup(getAudiovisualGroups()),
                },
                {
                    name: 'Objetivos FFVV',
                    path: '/objetivos-fuerza-ventas',
                    active: true,
                    access: belongGroup(getObjectivesEditorsGroups()),
                },
                {
                    name: 'Importación nóminas',
                    path: '/importacion-nominas',
                    active: false,
                    access: belongGroup(getRRHHGroups()),
                },
                {
                    name: 'Incongruencias en facturación',
                    path: '/incongruencias',
                    active: true,
                    access: belongGroup(getRRHHGroups()),
                },
                {
                    name: 'Generación de guiones para cursos',
                    path: '/generacion-guiones-para-cursos',
                    active: true,
                    access: belongGroup(getScriptCourseGeneratorGroups()),
                },
                {
                    name: 'Dashboard asesores académicos',
                    path: '/dashboard-asesores-academicos',
                    active: true,
                    access: belongGroup(getRRHHGroups()),
                },
                {
                    name: 'Generación de HTML de traducciones',
                    path: '/revision-traducciones-html',
                    active: false,
                    access: belongGroup(getTranslatorGroups()),
                },
                {
                    name: 'Asignación fuerzas de ventas a matrículas',
                    path: '/asignacion-fuerza-ventas-a-matriculas',
                    active: true,
                    access: belongGroup(getAssignFFVVGroups()),
                },
                {
                    name: 'Peticion acceso a dashboard',
                    path: '/peticion-acceso-dashboard',
                    active: true,
                    access: true,
                },
                {
                    name: 'Eliminar permisos de acceso a dashboard',
                    path: '/eliminar-dashboard-usuario',
                    active: belongGroup(getDashboardAccessGroups()),
                    access: belongGroup(getDashboardAccessGroups()),
                },
                {
                    name: 'Gestión de acceso a dashboard',
                    path: '/gestion-peticion-acceso',
                    active: belongGroup(getDashboardAccessGroups()),
                    access: belongGroup(getDashboardAccessGroups()),
                },
            ]
        },
    ]
}