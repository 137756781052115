<template>
    <v-container>
        <template v-if="general_loading">
            <Loading></Loading>
        </template>
        <template v-else>
            <v-row>
                <v-col cols="12" class="section-title">
                    <span class="main-title-page">Gestión de peticiones de acceso</span>
                </v-col>
                <v-col>
                    <Vue3EasyDataTable
                        class="primary-table"
                        :headers="columns_table_requests"
                        :items="access_request"
                        buttons-pagination
                        :rows-per-page="10"
                        rows-per-page-message="Elementos por página: "
                        rows-of-page-separator-message="de"
                        empty-message="No hay datos disponibles"
                    >
                        <template #item-dashboard_title="item">
                            <span>{{item['dashboard']['title']}}</span>
                        </template>

                        <template #item-dashboard_url="item">
                            <span>{{item['dashboard']['url']}}</span>
                        </template>

                        <template #item-actions="item">
                            <div class="d-flex flex-row gap-5">
                                <v-btn
                                    class="btn button-bg-white"
                                    @click="handleAccessPetition('ACCEPT', item.id_request)"
                                >
                                    Aceptar
                                </v-btn>
                                <v-btn
                                    class="btn button-bg-white"
                                    @click="handleAccessPetition('DENY', item.id_request)"
                                >
                                    Rechazar
                                </v-btn>
                            </div>
                        </template>

                        <template #item-created_at="item">
                            {{formatOnlyDate(item['created_at'])}}
                        </template>
                    </Vue3EasyDataTable>
                </v-col>
            </v-row>
        </template>
    </v-container>
</template>

<script>
import Loading from "@/components/Generic/Loading.vue";
import {onBeforeMount, ref} from "vue";
import {getAccessRequestWS, handleAccessRequestWS} from "@/utils/looker_utils";
import {formatOnlyDate, showNotification} from "@/utils/aux_functions";
import Vue3EasyDataTable from "vue3-easy-data-table";
import {hasPermission} from "@/utils/auth";
import {useRoute} from "vue-router";

export default {
    name: "DashboardManageAccessPetitionsView",
    methods: {formatOnlyDate},
    components: {Vue3EasyDataTable, Loading},
    setup() {
        const route = useRoute();
        let general_loading = ref(false)
        let access_request = ref([])
        let columns_table_requests = ref([
            {text: 'Título', value: 'dashboard_title'},
            {text: 'ID Dashboard', value: 'id_dashboard'},
            {text: 'Usuario', value: 'id_user'},
            {text: 'Fecha', value: 'created_at'},
            {text: '', value: 'actions'},
        ])

        onBeforeMount(async () => {
            await hasPermission(route);
            await loadAllAccessRequest()
        })

        async function loadAllAccessRequest() {
            general_loading.value = true;

            let response = await getAccessRequestWS()

            if (Array.isArray(response)) {
                access_request.value = response
            } else {
                showNotification("error", "Ha ocurrido un error al cargar los dashboards")
            }

            general_loading.value = false;
        }

        async function handleAccessPetition(accept, request_id) {
            general_loading.value = true;
            let response = await handleAccessRequestWS({request_id, action: accept})
            general_loading.value = false;
            if (response['is_handled']){
                await loadAllAccessRequest()
                showNotification('success', "Se ha procesado correctamente la solicitud")
            }else{
                showNotification('error', 'Ha ocurrido un error al procesar la solicitud')
            }
        }

        return {
            handleAccessPetition,

            columns_table_requests,
            general_loading,
            access_request,
        }
    }
}
</script>

<style scoped lang="scss">

</style>