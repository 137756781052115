<template>
    <v-container class="container-manager">
        <v-row
               align="center"
               align-content="center"
               class="row-height"
        >
            <ManagerAdminData :dashboard="{
                            titulo: 'Gestor de Dashboard/roles',
                            ruta: '/admin/gestor-dashboard-roles',
                             icono: 'manager-dashboard.svg'
                }"></ManagerAdminData>
            <ManagerAdminData :dashboard="{
                            titulo: 'Gestor de grupos/roles',
                            ruta: '/admin/gestor-grupos-roles',
                             icono: 'manager-groups.svg'
                }"></ManagerAdminData>
            <ManagerAdminData :dashboard="{
                            titulo: 'Gestor de roles',
                            ruta: '/admin/gestor-roles',
                             icono: 'manager-roles.svg'
                }"></ManagerAdminData>
        </v-row>
    </v-container>
</template>

<script>
import ManagerAdminData from "@/components/Admin/ManagerAdminData.vue";
import {onBeforeMount, ref} from "vue";
import {hasPermission} from "@/utils/auth";
import {useRoute} from "vue-router";

export default {
    name: 'ManagerAdminView',
    components: {
        ManagerAdminData
    },

    setup() {
        const route = useRoute();


        onBeforeMount(async () => {
            await hasPermission(route);
        })

        return {
        };
    }
};
</script>
<style lang="scss">
@import "@/styles/components/manager-admin.scss";
</style>