import store from "@/store";
import {useRouter} from "vue-router";
import {aicoreBackendPetition} from "@/utils/aicore_petition";
import {b64EncodeUnicode} from "@/utils/aux_functions";

export class Endpoints {
    /*
     * This class basically simulates being an enumerate object.
     * This way we can select a connection avoiding typos.
     */
    static AICORE = new Endpoints('AICORE');
    static EXTERNOS = new Endpoints('EXTERNOS');
    static ANALYTICS = new Endpoints('ANALYTICS');
    static LOOKER = new Endpoints('LOOKER');

    constructor(name) {
        this.name = name;
    }
    toString() {
        return `Endpoints.${this.name}`;
    }
}


export async function hasPermission(route) {
    const router = useRouter();
    let has_permission = true;

    if (isLogged()) {
        switch (route.name) {
            case 'gestor-grupos-roles':
            case 'gestor-dashboard-roles':
            case 'gestor-roles':
            case 'admin':
            case 'asignacion-fuerza-ventas-a-matriculas':
            case 'incongruencias':
                has_permission = belongGroup(getAdminGroups());
                break;
            case 'creacion-video-producto':
                has_permission = belongGroup(getAudiovisualGroups());
                break;
            case 'importacion-nominas':
                has_permission = belongGroup(getRRHHGroups());
                break;
            case 'objetivos-fuerza-ventas':
                has_permission = belongGroup(getObjectivesEditorsGroups());
                break;
            case 'generacion-guiones-para-cursos':
                has_permission = belongGroup(getScriptCourseGeneratorGroups());
                break;
            case 'gestion-peticion-acceso':
            case 'eliminar-dashboard-usuario':
                has_permission = belongGroup(getDashboardAccessGroups());
                break;
        }

        if (!route.fullPath.includes('acceso-denegado')) {
            await saveLogVisit(route.fullPath, has_permission)
        }
        return (has_permission ? true : router.push({name: 'not-allowed'}))
    }
    return router.push({name: 'inicio'})

}

export async function authenticate(username, password, ext = true) {

    let env_password = process.env.VUE_APP_AICORE_AUTH_PASSWORD;
    let env_user = process.env.VUE_APP_AICORE_AUTH_USER;
    let env_url = process.env.VUE_APP_AICORE_URL_LOGIN;


    if (ext) {
        env_password = process.env.VUE_APP_AICORE_AUTH_EXT_PASSWORD;
        env_user = process.env.VUE_APP_AICORE_AUTH_EXT_USER;
        //env_url = process.env.VUE_APP_AICORE_URL_LOGIN_LOOKER;
        env_url = process.env.VUE_APP_AICORE_URL_LOGIN_EXT;

    }

    return $.ajax({
        type: "POST",
        url: env_url,
        data: {
            username_ldap: username,
            password_ldap: b64EncodeUnicode(password),
            username_aicore: env_user,
            password_aicore: b64EncodeUnicode(env_password),
        }
    });
}

export async function getAicoreToken(ext = false) {
    let env_password = process.env.VUE_APP_AICORE_AUTH_PASSWORD;
    let env_user = process.env.VUE_APP_AICORE_AUTH_USER;
    let env_token = process.env.VUE_APP_AICORE_URL_TOKEN;

    if (ext) {
        env_password = process.env.VUE_APP_AICORE_AUTH_EXT_PASSWORD;
        env_user = process.env.VUE_APP_AICORE_AUTH_EXT_USER;
        //env_token = process.env.VUE_APP_AICORE_URL_TOKEN_LOOKER
        env_token = process.env.VUE_APP_AICORE_URL_TOKEN_EXT

    }


    let password = Math.random().toString(36).substring(2, 6) + env_password + Math.random().toString(36).substring(2, 6)

    return $.ajax({
        type: "POST",
        url: env_token,
        data: {
            username: env_user,
            password: btoa(password)
        }
    });
}

/* Request token from diferent endpoints.
 * @param {Endpoints} endpoint Value name of the available endpoints such as "aicore"
 */
export async function fetchToken(endpoint){
    let env_password = process.env.VUE_APP_AICORE_AUTH_PASSWORD;
    let env_user = process.env.VUE_APP_AICORE_AUTH_USER;
    let env_token = process.env.VUE_APP_AICORE_URL_TOKEN;

    switch(endpoint){
        case Endpoints.AICORE.name:
            break;
        case Endpoints.EXTERNOS.name:
            env_password = process.env.VUE_APP_AICORE_AUTH_EXT_PASSWORD;
            env_user = process.env.VUE_APP_AICORE_AUTH_EXT_USER;
            env_token = process.env.VUE_APP_AICORE_URL_TOKEN_EXT;
            break;
        case Endpoints.ANALYTICS.name:
            env_password = process.env.VUE_APP_AISEO_ANALYTICS_AUTH_EXT_PASSWORD;
            env_user = process.env.VUE_APP_AISEO_ANALYTICS_AUTH_EXT_USER;
            env_token = process.env.VUE_APP_AISEO_ANALYTICS_URL_TOKEN_EXT;
            break;
        case Endpoints.LOOKER.name:
            env_password = process.env.VUE_APP_AICORE_AUTH_EXT_PASSWORD;
            env_user = process.env.VUE_APP_AICORE_AUTH_EXT_USER;
            env_token = process.env.VUE_APP_AICORE_URL_TOKEN_LOOKER;
            break;
        default:
            throw new Error('Invalid endpoint selected. There is no endpoint: ' + endpoint);
    }

    let password = Math.random().toString(36).substring(2, 6) + env_password + Math.random().toString(36).substring(2, 6)
    return $.ajax({
        type: "POST",
        url: env_token,
        data: {
            username: env_user,
            password: env_password
        }
    });
}


export function isLogged() {
    return store.getters.getUsername !== ""
}

export async function logout() {
    localStorage.clear()
    store.commit('setUsername', "")
    store.commit('setAicoreToken', "")
}


export async function saveLogVisit(to, visited) {
    if (process.env.NODE_ENV !== 'development') {
        var data = {
            url: to,
            username: store.getters.getUsername,
            visualizado: visited ? 1 : 0,
            application: 'aicore'
        };
        await aicoreBackendPetition('GET', '/Logs/save_log_visualizacion_app', data, {ext: true});
    }
}


export function belongGroup(allowed_groups) {
    let user_groups = store.getters.getUserGroup
    let array_user_groups = user_groups.split(',')

    let belong = false;
    array_user_groups.forEach(group => {
        if (allowed_groups.includes(parseInt(group))) {
            belong = true;
        }
    })
    return belong;
}

export function getRRHHGroups() {
    let specific = [
        4743 /*DPTO.HUMAN RESOURCES*/,
        4744 /*DPTO.HUMAN RESOURCES.ADMIN*/,
        3639 /*CM.CORPORATE LEARNING COMMISSION.ADMIN*/
    ]
    return [...getAdminGroups(), ...specific]
}

export function getAudiovisualGroups() {
    let specific = [
        4540 /*GR.INNOVA STUDIOS*/,
        4541 /*GR.INNOVA STUDIOS.ADMIN*/,
    ]
    return [...getAdminGroups(), ...specific]
}

export function getObjectivesEditorsGroups() {
    let specific = [
        5738 /*CM.SALES.ADMIN*/,
        6009/*CM.STRATEGIC PARTNERSHIP.ADMIN*/,
        6862/*CM.LATAM GROWTH.ADMIN*/,
        3639 /*CM.CORPORATE LEARNING COMMISSION.ADMIN*/
    ]
    return [...getAdminGroups(), ...specific]
}

export function getScriptCourseGeneratorGroups() {
    let specific = [
        4540 /*GR.INNOVA STUDIOS*/,
        4541 /*GR.INNOVA STUDIOS.ADMIN*/,
        6057 /*GR.PRODUCT ANALYTICS*/,
        6058 /*GR.PRODUCT ANALYTICS.ADMIN*/,
    ]
    return [...getAdminGroups(), ...specific]
}

export function getTranslatorGroups() {
    let specific = [
        6168 /*DPTO.PRODUCT MANAGEMENT.ADMIN*/,
        6167 /*DPTO.PRODUCT MANAGEMENT*/,
        6074 /*AREA.BUSINESS AREA*/,
        3980 /*CM.PUBLISHING*/
    ]
    return [...getAdminGroups(), ...specific]
}

export function getAssignFFVVGroups() {
    let specific = [
        5455 /*GR.ENROLLMENT SUPPORT SERVICE.ADMIN*/,
    ]
    return [...getAdminGroups(), ...specific]
}

export function getAdminGroups() {
    return [
        5813/* PROYECTO IA CORE*/,
        5814/* ROYECTO IA CORE.ADMIN*/,
        3628/* GR.DEVOPS.ADMIN*/,
        5155/*GR.CODIR*/,
        6971/*PR.OPERACIONES-AICORE*/,
        4995/*CM.EDTECH*/,
        3556 /*CIA.MORENO HIDALGO*/,
        7594/*GR.EDUSEO.AI*/,
        7612/*GR.EDUCORE.AI*/,
    ]
}

export function getDevelopGroups() {
    return [
        5813/*PR.PROYECTO IA CORE*/,
        5814/*PR.PROYECTO IA CORE.ADMIN*/,
        6656/*DPTO.WEBS.ADMIN*/,
        6655/*DPTO.WEBS*/,
        7594/*GR.EDUSEO.AI*/,
        7612/*GR.EDUCORE.AI*/,
    ]
}

export function getAcademicDashboardGroups() {
    let specific = [
        4201 /*GR.CALL CENTER BRANDING.ADMIN*/,
        4056 /*GR.CALL CENTER EUROCURSOS.ADMIN*/,
        4054 /*GR.CALL CENTER EURO MASTERS.ADMIN*/,
        5455 /*GR.ENROLLMENT SUPPORT SERVICE.ADMIN*/,
        5741 /*GR.CONTACT CENTER.ADMIN*/,
    ]

    return [...getAdminGroups(), ...specific]
}

export function getDashboardAccessGroups() {
    let specific = [
        7613 /* GR.EDUCORE.AI.ADMIN */
    ]

    return  [...specific]
}
