<template>
    <v-container>
        <v-row>
            <v-col cols="12" sm="9" lg="10">
                <span class="main-title-page">Dashboards de un usuario</span>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="10" sm="7" md="6" lg="4">
                <v-text-field
                    v-model.trim="identifier_user"
                    @keyup.enter="getAllDashboardAccessByUser"
                    class="text-field-secondary input-error"
                    placeholder="Identificador del usuario"
                    append-inner-icon="mdi-magnify"
                    single-line
                    bg-color="white"
                    color="transparent"
                    hide-details
                ></v-text-field>

            </v-col>
            <v-col cols="2" sm="1" md="1" class="align-self-center">
                <img :src="require('@/assets/icons/delete-left-solid.svg')" alt="Eliminar campo usuario"
                     class="delete-filter" @click="deleteFilter">
            </v-col>
        </v-row>
        <v-row>
            <v-col class="list-title">
                <span>Listado de dashboards</span>
            </v-col>
        </v-row>
        <Loading v-if="loading"></Loading>
        <template v-else>
            <v-row no-gutters>
                <v-col cols="12" v-if="user_dashboard_access.length>0">
                    <Vue3EasyDataTable
                        :hide-footer="user_dashboard_access.length<=50"
                        class="primary-table"
                        :headers="columns_table"
                        :items="user_dashboard_access"
                        :loading="loading"
                        :rows-per-page="50"
                        buttons-pagination
                        rows-per-page-message="Elementos por página: "
                        rows-of-page-separator-message="de"
                        empty-message="No hay datos disponibles"
                    >
                        <template #item-action="{id_dashboard}">
                            <v-btn
                                class="btn button-primary"
                                @click="deleteAccessPermission(id_dashboard)"
                            >
                                Eliminar acceso
                            </v-btn>
                        </template>
                    </Vue3EasyDataTable>
                </v-col>
                <v-col class="default-text" v-else>
                    <span>{{"Debe introducir un usuario para mostrar los dashboards a los que tiene acceso" }}</span>
                </v-col>

            </v-row>
        </template>
    </v-container>
</template>

<script>
import Loading from "@/components/Generic/Loading.vue";
import Vue3EasyDataTable from "vue3-easy-data-table";
import {onBeforeMount, ref} from "vue";
import {showNotification} from "@/utils/aux_functions";
import {getAllDashboardByUserWS} from "@/utils/dashboards";
import {deleteDashboardAccessByUserWS, getDashboardAccessByUserWS} from "@/utils/looker_utils";
import {useRoute} from "vue-router";
import {hasPermission} from "@/utils/auth";

export default {
    name: "DashboardViewAccess",
    components: {Vue3EasyDataTable, Loading},
    setup() {
        const route = useRoute();

        let identifier_user = ref("")
        const columns_table = ref([
            {text: 'ID', value: 'id_dashboard'},
            {text: 'URL', value: 'url'},
            {text: 'Nombre', value: 'title'},
            {text: 'Acciones', value: 'action'},
        ]);
        
        let loading = ref(false)
        
        let user_dashboard_access = ref([])
        let current_user = ""

        onBeforeMount(async () => {
            await hasPermission(route);
        })

        async function getAllDashboardAccessByUser() {
            loading.value = true
            if (validate_user(identifier_user.value)){
                const response = await getDashboardAccessByUserWS(identifier_user.value)
                console.log(response)

                if (response.status !== 404){
                    user_dashboard_access.value = [...response['data']];

                }else{
                    showNotification('error', 'El usuario no tiene permisos directos a ningún dashboard')
                    user_dashboard_access.value = []
                }

                current_user = identifier_user.value
            }else{
                showNotification('error', "El usuario no es válido")
            }

            loading.value = false
        }

        function validate_user(username) {
            const regex = /^[a-z]+\.{1}[a-z]+$/i;
            return ((regex.exec(username)) !== null);
        }

        function deleteFilter() {
            identifier_user.value = ""
        }

        async function deleteAccessPermission(id_dashboard){
            const response = await deleteDashboardAccessByUserWS(current_user, id_dashboard)

            if (response){
                showNotification('success', response['detail'])
                await getAllDashboardAccessByUser()
            }
        }

        return {
            deleteAccessPermission,
            getAllDashboardAccessByUser,
            deleteFilter,

            identifier_user,
            columns_table,
            user_dashboard_access,
            loading,
        }
    }
}
</script>

<style scoped lang="scss">

</style>