<template>
    <v-container>
        <template v-if="general_loading">
            <Loading></Loading>
        </template>
        <template v-else>
            <v-row>
                <v-col cols="12" class="section-title">
                    <span class="main-title-page">Petición de acceso a Dashboard</span>
                </v-col>
            </v-row>
            <div class="petitions-content">
                <div>
                    <v-row class="flex-column">
                        <v-col cols="12">
                            <span class="input-title">Dashboard al que solicita acceso</span>
                            <v-autocomplete
                                v-if="!search_by_url"
                                placeholder="Seleccione dashboard"
                                v-model="selected_dashboard"
                                class="autocomplete-secondary"
                                bg-color="white"
                                hide-details
                                :items="dashboards_list"
                                return-object
                            >
                                <template #item="{props, item, index}">
                                    <div>
                                        <v-divider
                                            v-if="item.raw.is_divider"
                                            :key="'divider-' + index"
                                            class="m-0"
                                        ></v-divider>
                                        <v-list-item
                                            class="group-name-item"
                                            v-else-if="item.raw['type'] === 'folder'"
                                        >
                                            <v-list-item-title class="group-name">
                                                <span style="cursor: pointer">{{item?.raw['nombre']}}</span>
                                            </v-list-item-title>
                                        </v-list-item>
                                        <v-list-item
                                            v-else
                                            v-bind="props"
                                            :title="item?.raw?.title"
                                        >
                                        </v-list-item>
                                    </div>
                                </template>
                            </v-autocomplete>
                            <template v-else>
                                <div class="url-search-container">
                                    <v-text-field
                                        class="text-field-secondary"
                                        v-model="selected_dashboard_url"
                                        placeholder="Introduzca una URL"
                                        single-line
                                        bg-color="white"
                                        color="transparent"
                                        hide-details
                                    ></v-text-field>
                                    <v-btn
                                        class="btn button-primary"
                                        @click="selectDashboardByURL"
                                    >
                                        Buscar
                                    </v-btn>
                                </div>
                            </template>
                            <v-checkbox
                                v-model="search_by_url"
                                hide-details
                                color="blue lighten-3"
                                label="Pulse si quiere buscar por URL, ej: https://educaedtech.educore.ai/dashboards/485"
                                :true-value="1"
                                :false-value="0"
                            ></v-checkbox>
                        </v-col>
                        <v-col cols="12">
                            <span class="input-title">Dashboard seleccionado</span>
                            <p v-if="variableIsDefined(selected_dashboard)">
                                <v-text-field
                                    class="text-field-secondary"
                                    bg-color="white"
                                    color="transparent"
                                    v-model="selected_dashboard['title']"
                                    disabled
                                >
                                </v-text-field>
                            </p>
                            <p v-else>
                                No hay seleccionado un dashboard válido
                            </p>
                        </v-col>
                        <v-col cols="12">
                            <v-btn
                                class="btn button-primary"
                                @click="createAccessRequest"
                                :disabled="Boolean(search_by_url && !variableIsDefined(selected_dashboard))"
                            >
                                Enviar petición de acceso
                            </v-btn>
                        </v-col>
                    </v-row>
                </div>

                <div>
                    <v-row>
                        <v-col>
                            <span class="input-title">Dashboard a los que ha solicitado acceso</span>

                            <div class="request-list-container">
                                <div class="request-container" v-for="request in access_request">
                                    <p>Titulo: {{request['dashboard']['title']}}</p>
                                    <p>Estado: {{getRequestStatus(request['action'])}}</p>
                                </div>
                            </div>
                        </v-col>
                    </v-row>
                </div>
            </div>
        </template>
    </v-container>
</template>

<script>
import Loading from "@/components/Generic/Loading.vue";
import {onBeforeMount, ref} from "vue";
import {
    createAccessRequestWS,
    getAccessRequestWS,
    getAllLookerDashboardsWS,
    getAllLookerFoldersWS
} from "@/utils/looker_utils";
import {showNotification, variableIsDefined} from "@/utils/aux_functions";
import {useStore} from "vuex";

export default {
    name: "DashboardAccessPetitionView",
    methods: {variableIsDefined},
    components: {Loading},
    setup() {
        const store = useStore()
        let general_loading = ref(false)
        let dashboards_list = ref()
        let banned_dashboards_list = ref([])

        let access_request = ref()

        let selected_dashboard = ref()
        let selected_dashboard_url = ref()

        let search_by_url = ref(0)

        let banned_folders = [9,6,20];

        onBeforeMount(async () => {
            await loadAllAvailableDashboards()
            await loadAllAccessRequest()
        })

        async function loadAllAvailableDashboards() {
            general_loading.value = true;

            let response = await getAllLookerFoldersWS()

            if (Array.isArray(response)) {
                let filtered_dashboard_list = []

                response.forEach(folder => {
                    if (!banned_folders.some(banned_folder => banned_folder === folder['id_folder'])){
                        filtered_dashboard_list.push({nombre: folder['nombre'], type: 'folder'})
                        filtered_dashboard_list.push({is_divider: true})

                        folder['published_dashboards'].forEach(dashboard => {
                            if (dashboard['activo']){
                                filtered_dashboard_list.push({...dashboard, type: 'dashboard'})
                            }
                        })
                    }else{
                        folder['published_dashboards'].forEach(dashboard => {
                            if (dashboard['activo']){
                                banned_dashboards_list.value.push({...dashboard, type: 'dashboard'})
                            }
                        })
                    }
                })
                dashboards_list.value = filtered_dashboard_list;
            } else {
                showNotification("error", "Ha ocurrido un error al cargar los dashboards")
            }

            general_loading.value = false;
        }

        async function selectDashboardByURL() {
            const url = selected_dashboard_url.value;
            const number = url.match(/\/(\d+)$/)?.[1];

            selected_dashboard.value = dashboards_list.value.find(dashboard => dashboard['id_dashboard'] === parseInt(number));

            if (!variableIsDefined(selected_dashboard.value)){
                selected_dashboard.value = banned_dashboards_list.value.find(dashboard => dashboard['id_dashboard'] === parseInt(number));
            }

            if (!variableIsDefined(selected_dashboard.value)){
                showNotification('error', 'El dashboard seleccionado no existe o esta desactivado')
            }
        }

        async function createAccessRequest(){
            general_loading.value = true;

            if (!userHaveAccess()){
                let response = await createAccessRequestWS(selected_dashboard.value['id_dashboard'])

                if (variableIsDefined(response) && response['status'] === 201) {
                    await loadAllAvailableDashboards()
                    await loadAllAccessRequest()
                    showNotification("success", "Se ha creado la solicitud correctamente")
                } else if (variableIsDefined(response) && response['response']['status'] === 400){
                    showNotification("error", "El usuario ya tiene acceso o su solicitud no ha sido gestionada")
                } else{
                    showNotification("error", "Ha ocurrido un error al enviar la solicitud")
                }
            }else{
                showNotification('error', 'Ya tiene acceso a este dashboard')
            }


            general_loading.value = false;
        }

        async function loadAllAccessRequest() {
            general_loading.value = true;

            let response = await getAccessRequestWS(false)

            if (Array.isArray(response)) {
                access_request.value = response.filter(request => {
                    return request['id_user'] === store.getters.getUsername
                })
            } else {
                showNotification("error", "Ha ocurrido un error al cargar los dashboards")
            }

            general_loading.value = false;
        }

        function getRequestStatus(status) {
            let selected_status

            switch (status) {
                case "ACCEPT":
                    selected_status = "Aceptada"
                    break;
                case "DENY":
                    selected_status = "Rechazada"
                    break;
                default:
                    selected_status = "Sin gestionar"
                    break;
            }

            return selected_status
        }

        function userHaveAccess() {
            let have_access = access_request.value.some(request=> {
                let request_exist = request['id_dashboard'] === selected_dashboard.value['id_dashboard']
                let request_accepted = getRequestStatus(request['action']) === "Aceptada"

                return request_exist && request_accepted;
            })

            return have_access
        }


        return {
            selectDashboardByURL,
            createAccessRequest,
            getRequestStatus,

            access_request,
            general_loading,
            dashboards_list,
            selected_dashboard,
            search_by_url,
            selected_dashboard_url,
        }
    }
}
</script>

<style lang="scss">

    .group-name-item {
        &:hover{
            background: transparent;
        }
        .group-name{
            font-weight: bold;
        }
    }

    .petitions-content {
        display: grid;
        gap: 20px;

        .request-list-container {
            display: flex;
            flex-direction: column;
            gap: 10px;
            overflow: auto;
            max-height: 50vh;

            .request-container {
                border: 1px solid $gray-light;
                border-radius: 8px;
                padding: 10px;
                background: $white;
            }
        }

    }

    .url-search-container {
        display: flex;
        gap: 10px;
        align-items: center;
    }

    @media(min-width: 768px){
        .petitions-content {
            grid-template-columns: 50% 1fr;
            gap: 10vw;
        }
    }
</style>